import { Box, List, ListItemText, styled, Typography } from '@mui/material';

export const ContentContainer = styled(Box)(({ width }) => ({
    padding: '0 2rem 4rem 2rem',
    width: width || '48rem',
    maxHeight: '70vh',
    overflowY: 'scroll',
}));

export const UnOrderedList = styled(List)({
    listStyleType: 'disc',
    paddingLeft: '1.5rem',
});

export const ListItem = styled(ListItemText)({
    display: 'list-item',
    justifyContent: 'center',
    fontWeight: 400,
    color: '#4f4f4f',

    '& span, & svg': {
        fontSize: '15px',
    },
});

export const Footer = styled(Box)({
    height: '2rem',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    padding: '0.2rem 1rem',
    borderTop: '1px solid #e0e0e0',
});

export const FooterText = styled(Typography)({
    fontSize: '15px',
    fontWeight: 400,
    color: '#4f4f4f',
});
