import * as fieldControlers from 'store/formControl/fieldControllers';
import { FIELD_FILTER_OPERATIONS } from './constants';
import _, { isDate, isEmpty, isNil, isObject, isUndefined, mapValues, omitBy, reduce } from 'lodash';

export const getFormFieldConfig = function ({ fieldConfig, formId, helperText = 'This field is required', disabled = false }) {
    const { required, label } = fieldConfig;

    return {
        ...fieldConfig,
        ...fieldControlers,
        label: required ? `${label}*` : label,
        formId,
        helperText,
        disabled,
    };
};

export const getLookup = (lookup, dropdownValues, fieldName) => {
    return {
        ...lookup,
        dataSource:
            !lookup?.dataSource && dropdownValues[fieldName]?.dataSource
                ? dropdownValues[fieldName]?.dataSource
                : lookup?.dataSource
                  ? lookup?.dataSource
                  : [],
    };
};

/**
 * Groups fields by their header and returns an array of objects.
 *
 * Each object in the resulting array contains a `caption`, which is the
 * header of the grouped fields, and a `columns` array that holds the
 * corresponding fields associated with that header.
 *
 * @param {Array} fields - An array of field objects where each object
 *                         must contain a `header` property.
 * @returns {Array} An array of objects, each containing:
 *                  - `caption`: The header of the group.
 *                  - `columns`: An array of field objects associated
 *                                with that header.
 *
 *
 * @example
 *  Input:
 *  const fields = [
 *     { '@type': 'Field', caption: 'Started', dataField: 'startedDateTime', header: 'General' },
 *     { '@type': 'Field', caption: 'Direction', dataField: 'gateDirection', header: 'General' },
 *     { '@type': 'Field', caption: 'Full name', dataField: 'person.fullName', header: 'Person' },
 *  ];
 *
 *  Output:
 *  [
 *      { caption: 'General', columns: [ * General fields * ] },
 *      { caption: 'Person', columns: [ * Person fields * ] }
 *  ]
 */
export const groupFieldsByHeader = (fields = []) => {
    return fields.reduce((acc, field) => {
        const { header } = field;
        // Check if the header already exists in the accumulator
        let group = acc.find((g) => g.caption === header);

        if (!group) {
            // If it doesn't exist, create a new group
            group = { caption: header, columns: [], type: 'object' };
            acc.push(group);
        }

        // if the field is type list then add type to the group
        if (field.dataType === 'list') {
            group.type = field.dataType;
            group.dataField = field.dataField;
        } else {
            // set default filter operations for the field
            field.filterOperations = FIELD_FILTER_OPERATIONS;
            // Push the field into the corresponding group's columns
            if (field.dataField?.includes('[]')) {
                group.columns.push({ ...field, dataType: 'array', dataField: field.dataField.replace('[]', '.array') });
            } else group.columns.push(field);
        }
        return acc;
    }, []);
};

/**
 * Exclude field Appointment Number from Access Point Record form
 *
 * @param {Array} formattedFieldList - Out put of function groupFieldsByHeader
 * @param {Array} excludeFieldNameList - array of exclude
 */
export const excludeFieldList = (formattedFieldList = [], excludeFieldNameList = []) => {
    return formattedFieldList?.map((section) => {
        if (section?.caption === 'General') {
            const excludedColumn = section?.columns?.filter((col) => !excludeFieldNameList.includes(col.dataField));

            return { ...section, columns: excludedColumn };
        } else return section;
    });
};

export const deepOmitBy = (obj, predicate = isNil) => {
    if (!isObject(obj)) {
        return obj; // Return non-object values as is.
    }

    // Recursively process each property.
    const result = reduce(
        obj,
        (acc, value, key) => {
            if (predicate(value, key) && !isDate(value)) {
                // If the predicate matches, omit this property.
                return acc;
            }

            // Recurse for nested objects or arrays.
            const processedValue = isObject(value) && !isDate(value) ? deepOmitBy(value, predicate) : value;

            // Only add non-empty processed values
            if (processedValue !== undefined && !(isObject(processedValue) && isEmpty(processedValue) && !isDate(value))) {
                acc[key] = processedValue;
            }

            return acc;
        },
        Array.isArray(obj) ? [] : {}
    );

    return result;
};
