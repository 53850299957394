export const toQueryString = (obj) => {
    const query = Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value === undefined) return acc;
        return acc + `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    }, '');
    if (!query) return '';
    return query.slice(0, -1);
};

/**
 * Parse the inputted url and return the query params
 * @param param - if provided, it will return the value of the param, default is undefined
 * @param url - if provided, it will parse the url, default is window.location
 * @returns {module:url.URLSearchParams|string} if param is provided, it will return the value of the param, otherwise it will return the URLSearchParams object
 */
export const getQueryParams = (param, url) => {
    if (!url) {
        url = window.location.search;
    }
    const urlParams = new URLSearchParams(url);
    if (param) {
        return urlParams.get(param);
    }
    return urlParams;
};
