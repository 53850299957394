import { Navigate, Outlet } from 'react-router-dom';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function ProtectedRoute({ isAllowed, redirectPath = '/', children }) {
    const currentUrl = window?.location.href;
    const redirectUrl = `${redirectPath}?backUrl=${encodeURIComponent(currentUrl)}`;
    if (!isAllowed) return <Navigate to={redirectUrl} replace />;

    return children ? children : <Outlet />;
}

export default ProtectedRoute;
