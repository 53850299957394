import { axiosLasta } from 'config';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchAuthTypeList } from 'services/authorizeService';
import { FormCompanySaga } from 'store/formControl/formCompanySaga';
import { getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';
import notificationsHandler from 'utils/notificationsHandler';
import { throttleAction } from 'utils/sagaHelpers';
import { companyAuthorizationListActions } from './companyAuthorizationListSlice';
import { transformAuthorizedCompany } from './companyAuthorizedListMapper';

export function* fetchAllLocations() {
    try {
        const response = yield axiosLasta('/location/all');
        const { data } = response;
        data.sort(function (a, b) {
            var textA = a?.yardName?.toUpperCase();
            var textB = b?.yardName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        yield put(companyAuthorizationListActions.setAllLocations(data));
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchAllLocations() {
    yield throttleAction(companyAuthorizationListActions.fetchAllLocations.type, fetchAllLocations);
}

export function* fetchAuthorizedListPerLocation({ payload: location }) {
    try {
        const response = yield axiosLasta(`/client/access-organization/location/${location.id}`);
        const { data } = response;

        const modifiedAuthorizedList = transformAuthorizedCompany(data);
        yield put(companyAuthorizationListActions.fetchAuthorizedLocationSuccess(modifiedAuthorizedList));
    } catch (err) {
        yield put(companyAuthorizationListActions.submitFormFailed(err));
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchingAuthorizedListPerLocation() {
    yield throttleAction(companyAuthorizationListActions.fetchAuthorizedListPerLocation.type, fetchAuthorizedListPerLocation);
}

export function* fetchMasterList() {
    try {
        const response = yield axiosLasta(`/organization/all`);
        const { data } = response;
        yield put(companyAuthorizationListActions.fetchMasterListSuccess(data));
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* fetchPerson() {
    try {
        const response = yield axiosLasta(`/client/personnel/all`);
        const { data } = response;
        data.sort(function (a, b) {
            var textA = a?.firstName?.toUpperCase();
            var textB = b?.firstName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        const adjustedData = data.map((el) => {
            return { ...el, fullName: `${el.firstName} ${el.lastName}` };
        });

        yield put(companyAuthorizationListActions.fetchPersonSuccess(adjustedData));
    } catch (err) {
        yield put(companyAuthorizationListActions.fetchPersonError(err.message));
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* fetchState() {
    try {
        const response = yield axiosLasta(`/client/state/all`);
        const { data } = response;

        yield put(companyAuthorizationListActions.fetchStateSuccess(data));
    } catch (err) {
        yield put(companyAuthorizationListActions.fetchPersonError(err.message));
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchingStateStart() {
    yield throttleAction(companyAuthorizationListActions.fetchStateStart.type, fetchState);
}

export function* onFetchingPersonsStart() {
    yield throttleAction(companyAuthorizationListActions.fetchPersonStart.type, fetchPerson);
}

export function* onFetchingMasterListStart() {
    yield throttleAction(companyAuthorizationListActions.fetchMasterListStart.type, fetchMasterList);
}

export function* onSubmitFormStart() {
    yield takeLatest(companyAuthorizationListActions.submitFormStart, FormCompanySaga);
}

export function* onAddItemToMasterList() {
    yield takeLatest(companyAuthorizationListActions.addItemToMasterList.type, FormCompanySaga);
}
export function* onAddItemToAuthorizedList() {
    yield takeLatest(companyAuthorizationListActions.addItemToAuthorizedList.type, FormCompanySaga);
}

export function* fetchAllAuthTypes({ payload: locationId }) {
    try {
        if (locationId) {
            const data = yield call(fetchAuthTypeList);
            yield put(companyAuthorizationListActions.setAuthTypeList(data));
        }
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchAllAuthTypes() {
    yield throttleAction(companyAuthorizationListActions.fetchAuthTypeList, fetchAllAuthTypes);
}

export function* fetchAuthorizedItemAuthTypes({ payload: dataPayload }) {
    try {
        if (dataPayload.personId) {
            const response = yield axiosLasta(
                `/client/authorization-type/authorized-organization/${dataPayload.personId}/location/${dataPayload.locationId}`
            );
            const { data } = response;
            yield put(companyAuthorizationListActions.fetchAuthorizedItemAuthTypes(data));
        }
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchAuthorizedItemAuthTypes() {
    yield throttleAction(companyAuthorizationListActions.fetchAuthorizedItemAuthTypesStart, fetchAuthorizedItemAuthTypes);
}

export function* deleteAuthorizedItem({ payload: dataPayload }) {
    try {
        let message;
        const response = yield axiosLasta.delete(
            `/client/access-organization/authorized-organization/${dataPayload.personId}/location/${dataPayload.location.id}`
        );

        ({ message } = response.data);

        if (message.includes('SUCCESS')) {
            yield put(companyAuthorizationListActions.fetchAuthorizedListPerLocation(dataPayload.location));
            yield put(companyAuthorizationListActions.resetItemsAuthorizations());
            yield put(companyAuthorizationListActions.setSelectedAlert(null));
            yield put(companyAuthorizationListActions.deleteSuccess());
            yield notificationsHandler({
                title: 'Successfully removed company',
                variant: 'success',
            });
        }
    } catch (err) {
        yield notificationsHandler({ title: 'Error removing person', err });
    }
}

export function* onDeleteAuthorizedItem() {
    yield takeEvery(companyAuthorizationListActions.deleteAuthorizedItem, deleteAuthorizedItem);
}

export function* deleteAuthType({ payload: dataPayload }) {
    try {
        let message;
        const getAuthTypeIds = {
            personId: dataPayload.personId,
            locationId: dataPayload.location.id,
        };
        const response = yield axiosLasta.delete(
            `/client/access-organization/authorized-organization/${dataPayload.personId}/location/${dataPayload.location.id}/auth-type/${dataPayload.authType.id}`
        );

        ({ message } = response.data);

        if (message.includes('SUCCESS')) {
            yield put(companyAuthorizationListActions.fetchAuthorizedItemAuthTypesStart(getAuthTypeIds));
            yield put(companyAuthorizationListActions.fetchAuthorizedListPerLocation(dataPayload.location));
            yield notificationsHandler({
                title: 'Successfully removed authorization',
                variant: 'success',
            });
        }
    } catch (err) {
        yield notificationsHandler({
            title: 'Error removing authorization',
            err,
        });
    }
}

export function* onDeleteAuthType() {
    yield takeEvery(companyAuthorizationListActions.deleteAuthType, deleteAuthType);
}
export function* companyAuthorizationSaga() {
    yield all([
        call(onFetchAllLocations),
        call(onFetchingAuthorizedListPerLocation),
        call(onSubmitFormStart),
        call(onFetchingMasterListStart),
        call(onAddItemToMasterList),
        call(onAddItemToAuthorizedList),
        call(onFetchAllAuthTypes),
        call(onFetchAuthorizedItemAuthTypes),
        call(onDeleteAuthorizedItem),
        call(onDeleteAuthType),
        call(onFetchingPersonsStart),
        call(onFetchingStateStart),
    ]);
}
