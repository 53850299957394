import { call, put, select } from 'redux-saga/effects';
import { selectFormById } from 'store/formControl/formControlSelectors';
import { formControlActions } from 'store/formControl/formControlSlice';

import store from 'store/store';
import notificationsHandler from 'utils/notificationsHandler';
import { axiosLasta } from 'config';
import { EMAIL_REGEX_FORMAT } from 'utils/constants';
import { errorCodeToMessageMap, getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';

export function* FormOrganizationSaga({ payload: type }) {
    const formId = type.formId;
    const SSO_FIELDS = ['adminUser', 'adminEmail', 'keycloakUrl'];
    try {
        const {
            form: { values, errors },
        } = yield select(selectFormById(formId));
        let isFormValid = true;

        const { adminUser, adminEmail, keycloakUrl: _, commonName, enableSso, ...rest } = values;
        const isValidEmail = EMAIL_REGEX_FORMAT.test(adminEmail);
        Object.keys(errors).forEach((fieldName) => {
            if (errors[fieldName]) {
                if (!enableSso && SSO_FIELDS.includes(fieldName)) return;
                isFormValid = false;
                store.dispatch(formControlActions.setFieldTouched({ formId, fieldName }));
            }
        });

        if (enableSso) {
            if (!adminUser) {
                yield put(
                    formControlActions.setFieldError({
                        formId,
                        fieldName: 'adminUser',
                        error: true,
                    })
                );
                yield put(
                    formControlActions.setFieldTouched({
                        formId,
                        fieldName: 'adminUser',
                    })
                );
                isFormValid = false;
            }
            const errMsg = !adminEmail ? true : !isValidEmail ? 'Invalid email format' : '';
            if (errMsg) {
                yield put(
                    formControlActions.setFieldError({
                        formId,
                        fieldName: 'adminEmail',
                        error: errMsg,
                    })
                );
                yield put(
                    formControlActions.setFieldTouched({
                        formId,
                        fieldName: 'adminEmail',
                    })
                );
                isFormValid = false;
                yield put(type.actions.submitFormFailed());
                if (!isValidEmail) return;
            }
        }

        if (!isFormValid) {
            yield notificationsHandler({
                title: 'Please fill required fields',
                variant: 'warning',
            });
            yield put(type.actions.submitFormFailed());
            return;
        }

        let message;
        if (type.formStatus === 'add') {
            const enableSsoFields = enableSso ? { adminUser, adminEmail } : {};
            const payload = { ...rest, commonName: commonName || null, enableSso, ...enableSsoFields };
            const response = yield axiosLasta.post(type.postUrl, payload);
            ({ message } = response.data);
        }
        if (type.formStatus === 'edit') {
            const beforeEditData = type.beforeEditData;

            const hasChanged = Object.keys(values).reduce((hasChanged, fieldName) => {
                let setHasChanged = false;

                if (typeof values[fieldName] === 'object' && values[fieldName]?.name !== beforeEditData[fieldName]?.name) {
                    setHasChanged = true;
                }

                if (typeof values[fieldName] === 'string' && values[fieldName] !== beforeEditData[fieldName]) {
                    setHasChanged = true;
                }

                if (typeof values[fieldName] === 'boolean' && values[fieldName] !== beforeEditData[fieldName]) {
                    setHasChanged = true;
                }

                return !hasChanged ? setHasChanged : true;
            }, false);

            if (!hasChanged) {
                yield call(notificationsHandler, {
                    title: 'No changes detected',
                    variant: 'info',
                });
                yield put(type.actions.submitFormFailed());
                return;
            }
            const response = yield axiosLasta.put(type.putUrl, {
                ...values,
                commonName: values.commonName || null,
                id: beforeEditData.id,
                adminUser: enableSso ? values.adminUser : undefined,
                adminEmail: enableSso ? values.adminEmail : undefined,
            });
            ({ message } = response.data);
        }

        if (message.includes('already exist')) {
            yield put(
                formControlActions.setFieldError({
                    formId,
                    fieldName: type.fields.name.fieldName,
                    error: `Organization already exists`,
                })
            );
            yield put(type.actions.submitFormFailed());
            return;
        }

        if (message.includes('SUCCESS')) {
            yield put(type.actions.setFormStatus('viewOnly'));
            yield put(type.actions.fetchMasterListStart());
            yield put(type.actions.setAddPopupVisible(false));

            yield notificationsHandler({
                title: `SUCCESSFULLY ${type.formStatus === 'edit' ? 'EDITED' : 'ADDED'} ORGANIZATION`,
                variant: 'success',
            });
        }
        yield put(type.actions.submitFormSuccess());
    } catch (err) {
        const errMsg = err?.response?.data?.error;
        if (errMsg === errorCodeToMessageMap.DUPLICATE_EMAIL) {
            yield put(formControlActions.setFieldError({ formId, fieldName: 'adminEmail', error: errorCodeToMessageMap.EMAIL_ALREADY_EXIST }));
            yield put(formControlActions.setFieldTouched({ formId, fieldName: 'adminEmail' }));
        } else {
            const formattedError = getFormattedErrorMessageFromErrorObject(err);
            yield notificationsHandler({ title: formattedError });
        }
        yield put(type.actions.submitFormFailed(JSON.stringify(err.message)));
    }
}
