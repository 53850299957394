import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fetchingSSOConfigurationInProgress: false,
    fetchingSSOConfigurationError: null,
    ssoConfiguration: {},
};

const ssoConfigurationSlice = createSlice({
    name: 'ssoConfiguration',
    initialState,
    reducers: {
        fetchSSOConfigurationStart(state) {
            state.fetchingSSOConfigurationInProgress = true;
            state.fetchingSSOConfigurationError = null;
        },
        fetchSSOConfigurationSuccess(state, { payload: ssoConfiguration }) {
            state.ssoConfiguration = ssoConfiguration;
            state.fetchingAccessPointRecordsInProgress = false;
            state.fetchingSSOConfigurationInProgress = false;
        },
        fetchSSOConfigurationError(state, { payload: error }) {
            state.fetchingAccessPointRecordsInProgress = false;
            state.fetchingAccessPointRecordsError = error;
            state.fetchingSSOConfigurationInProgress = false;
        },
    },
});

export const { fetchSSOConfigurationStart } = ssoConfigurationSlice.actions;

export const ssoConfigurationActions = ssoConfigurationSlice.actions;
export const ssoConfigurationReducer = ssoConfigurationSlice.reducer;
