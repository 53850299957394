export const mainConfig = {
    tableId: 'cameraConfiguration',
    urlPath: 'monitoring/element/all',
};

export const tableColumnsConfig = [
    {
        columns: [
            {
                caption: 'Status',
                dataField: 'status',
                dataType: 'boolean',
                editable: false,
                fixed: true,
            },
            {
                caption: 'ID',
                dataField: 'id',
                dataType: 'string',
                editable: false,
                fixed: true,
            },
            {
                caption: 'Location',
                dataField: 'location.yardName',
                dataType: 'string',
                editable: false,
                fixed: true,
            },
            {
                caption: 'Family',
                dataField: 'family.name',
                dataType: 'string',
                editable: false,
                fixed: true,
            },
            {
                caption: 'Name',
                dataField: 'name',
                dataType: 'string',
                editable: false,
                fixed: true,
            },
        ],
    },
    {
        caption: 'General',
        columns: [
            {
                caption: 'Element Type',
                dataField: 'elementType',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Monitoring Object',
                dataField: 'monitoringObjects',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Class',
                dataField: 'monitoringElementClass',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Note',
                dataField: 'note',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Device',
        columns: [
            {
                caption: 'Manufacturer',
                dataField: 'manufacturer.name',
                dataType: 'string',
                editable: false,
            },

            {
                caption: 'Model',
                dataField: 'model',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Serial Number',
                dataField: 'deviceSerialNumber',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Software Version',
                dataField: 'softwareVersion',
                dataType: 'string',
                editable: false,
            },
        ],
    },

    {
        caption: 'Network',
        columns: [
            {
                caption: 'MAC Address',
                dataField: 'macAddress',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Subnet Mask',
                dataField: 'subnetMask',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'IPv4 Address',
                dataField: 'ipv4Address',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'IPv4 Gateway',
                dataField: 'ipv4Gateway',
                dataType: 'string',
                editable: false,
            },

            {
                caption: 'RTSP Port',
                dataField: 'port',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'HTTP Port',
                dataField: 'portHttp',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'External Port',
                dataField: 'portExternal',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'HTTPS',
                dataField: 'ssl',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Wi-Fi settings',
        columns: [
            {
                caption: 'SSID',
                dataField: 'ssid',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'WPA Preshared Key',
                dataField: 'wpaPresharedKey',
                dataType: 'string',
                editable: false,
            },

            {
                caption: 'Username',
                dataField: 'username',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Password',
                dataField: 'password',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'MMTX Main Stream',
        columns: [
            {
                caption: 'Source',
                dataField: 'sourceMain',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Type',
                dataField: 'streamProcessingMain',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Parameters',
                dataField: 'streamParametersMain',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'MMTX Sub Stream',
        columns: [
            {
                caption: 'Source',
                dataField: 'sourceSub',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Type',
                dataField: 'streamProcessingSub',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Parameters',
                dataField: 'streamParametersSub',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'MMTX',
        columns: [
            {
                caption: 'MMTX Name',
                dataField: 'mediaProxyName',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Direct (WebRTC On)',
                dataField: 'mtxDirect',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Stream Source',
        columns: [
            {
                caption: 'NVR Id',
                dataField: 'nvrId',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'NVR Channel',
                dataField: 'nvrChannelNumber',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Streams URLs',
        columns: [
            {
                caption: 'Main Stream Camera URL',
                dataField: 'urlStreamQualityMain',
                dataType: 'string',
                editable: false,
                width: 350,
            },
            {
                caption: 'Sub Stream Camera URL',
                dataField: 'urlStreamQualitySub',
                dataType: 'string',
                editable: false,
                width: 320,
            },
            {
                caption: 'Main Stream NVR URL',
                dataField: 'urlNvrStreamQualityMain',
                dataType: 'string',
                editable: false,
                width: 350,
            },
            {
                caption: 'Sub Stream NVR URL',
                dataField: 'urlNvrStreamQualitySub',
                dataType: 'string',
                editable: false,
                width: 350,
            },
            {
                caption: 'Main Stream MMTX URL',
                dataField: 'urlProxyStreamQualityMain',
                dataType: 'string',
                editable: false,
                width: 300,
            },
            {
                caption: 'Sub Stream MMTX URL',
                dataField: 'urlProxyStreamQualitySub',
                dataType: 'string',
                editable: false,
                width: 300,
            },
        ],
    },
];
