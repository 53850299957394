import PropTypes from 'prop-types';
import { Link, Stack } from '@mui/material';
import { ContentContainer, UnOrderedList, ListItem, Footer, FooterText } from './HelpContainerStyled';
import HelpSection from './HelpSection';

const HelpContainer = ({ children, width, overview, overviewList = [] }) => (
    <Stack>
        <ContentContainer width={width}>
            <HelpSection title="OVERVIEW" description={overview} hasTopDivider={false}>
                {overviewList.length > 0 && (
                    <UnOrderedList>
                        {overviewList.map((item, index) => (
                            <ListItem key={index}>{item}</ListItem>
                        ))}
                    </UnOrderedList>
                )}
            </HelpSection>
            {children}
        </ContentContainer>
        <Footer>
            <FooterText>
                {`For further support, email us at `}
                <Link href="mailto:cs@birdseye.ca" underline="none">
                    cs@birdseye.ca
                </Link>
                {'.'}
            </FooterText>
        </Footer>
    </Stack>
);

HelpContainer.propTypes = {
    children: PropTypes.node.isRequired,
    width: PropTypes.string || PropTypes.number,
    overview: PropTypes.string,
    overviewList: PropTypes.arrayOf(PropTypes.string),
};

export default HelpContainer;
