import SvgIcon from '@mui/material/SvgIcon';
import { ICON_SIZE, SVG_NAMESPACE, SVG_VIEW_BOX } from './iconConstants'; // Adjust the path as needed

const SSOIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns={SVG_NAMESPACE} viewBox={SVG_VIEW_BOX} width={ICON_SIZE.width} height={ICON_SIZE.height}>
                <path d="M 29 8.53125 C 23.785 8.53125 19.371 11.6525 17.25 16.0625 C 16.121 15.2855 14.81175 14.71875 13.34375 14.71875 C 9.61675 14.71875 6.6445 17.66775 6.4375 21.34375 C 2.7105 22.68875 5.9211895e-16 26.16375 0 30.34375 C 0 35.67175 4.32825 40 9.65625 40 L 41.09375 40 C 45.99975 40 50 35.99975 50 31.09375 C 50 26.54275 46.50775 22.92625 42.09375 22.40625 C 42.10975 22.16425 42.15625 21.9375 42.15625 21.6875 C 42.15625 14.4375 36.25 8.53125 29 8.53125 z M 31.208984 16.255859 L 34.386719 16.523438 L 34.732422 19.769531 L 33.767578 20.736328 L 33.767578 22.234375 L 32.273438 22.234375 L 32.230469 22.277344 L 32.230469 23.744141 L 30.767578 23.744141 L 30.732422 23.779297 L 30.732422 25.242188 L 29.271484 25.242188 L 28.498047 26.019531 C 28.827047 26.811531 29 27.644 29 28.5 C 29 32.084 26.084 35 22.5 35 C 18.916 35 16 32.084 16 28.5 C 16 24.916 18.916 22 22.5 22 C 23.356 22 24.190469 22.171047 24.980469 22.498047 L 31.208984 16.255859 z M 21.5 28 A 1.5 1.5 0 0 0 21.5 31 A 1.5 1.5 0 0 0 21.5 28 z" />
            </svg>
        </SvgIcon>
    );
};

export default SSOIcon;
