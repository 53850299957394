import { call, put, takeLatest, takeEvery, all, take, fork, cancel } from 'redux-saga/effects';
import { userLocationsActions } from './userLocationsSlice';
import { fetchUserLocations, fetchUserLocationsById, saveUserLocations } from 'services/userLocationService';
import { fetchOrganizationLocationsByOrganizationId } from 'services/organizationService';
import notificationsHandler from 'utils/notificationsHandler';
import { mapUserLocations } from '../mapper';

export function* fetchAllUserLocations() {
    try {
        const data = yield call(fetchUserLocations);
        const mappedData = mapUserLocations(data);
        yield put(userLocationsActions.fetchUserLocationsSuccess(mappedData));
    } catch (error) {
        yield put(userLocationsActions.fetchUserLocationsError(error.message));
    }
}

export function* onFetchUserLocationsStart() {
    yield takeLatest(userLocationsActions.fetchUserLocationsStart.type, fetchAllUserLocations);
}

export function* fetchUserLocationsAccess({ payload: id }) {
    try {
        const data = yield call(fetchUserLocationsById, id);
        yield put(userLocationsActions.fetchUserLocationsListSuccess(data[0]));
    } catch (err) {
        yield put(userLocationsActions.fetchUserLocationsListFailed());
        yield notificationsHandler({
            title: `Error fetching user's locations`,
        });
    }
}

export function* onFetchUserLocationStart() {
    yield takeEvery(userLocationsActions.fetchUserLocationsListStart.type, fetchUserLocationsAccess);
}

export function* saveOrganizationLocations({ payload: userLocations }) {
    try {
        const data = yield call(saveUserLocations, userLocations);
        if (data) {
            yield notificationsHandler({
                title: `SUCCESSFULLY UPDATE LOCATIONS'S ACCESS `,
                variant: 'success',
            });
            yield put(userLocationsActions.fetchUserLocationsStart());
        }
    } catch (err) {
        yield notificationsHandler({ title: 'Error submitting data!' });
    } finally {
        yield put(userLocationsActions.saveUserLocationsEnd());
    }
}

export function* onSaveUserLocationsAccess() {
    yield takeEvery(userLocationsActions.saveUserLocationsStart.type, saveOrganizationLocations);
}

export function* addLocationsFromSelectedOrgs(id) {
    try {
        const data = yield call(fetchOrganizationLocationsByOrganizationId, id);
        yield put(userLocationsActions.addLocationsFromSelectedOrgsSuccess(data));
    } catch (err) {
        yield put(userLocationsActions.addLocationsFromSelectedOrgsFailed());
    }
}

export function* onAddingLocationsFromSelectedOrgsStart() {
    yield takeEvery(userLocationsActions.addLocationsFromSelectedOrgsStart.type, function* (action) {
        const task = yield fork(addLocationsFromSelectedOrgs, action.payload);
        const result = yield take([userLocationsActions.cancel.type, userLocationsActions.addLocationsFromSelectedOrgsSuccess.type]);
        if (result.type === userLocationsActions.cancel.type) yield cancel(task);
    });
}

export function* fetchLocationsFromAssignedOrgs(id) {
    try {
        const data = yield call(fetchOrganizationLocationsByOrganizationId, id);
        yield put(userLocationsActions.initCurrentLocationsFromAssignedOrgsSuccess(data));
    } catch (err) {
        yield put(userLocationsActions.initCurrentLocationsFromAssignedOrgsFailed());
    }
}

export function* onFetchingLocationsFromAssignedOrgsStart() {
    yield takeEvery(userLocationsActions.initLocationsFromAssignedOrgsStart.type, function* (action) {
        const task = yield fork(fetchLocationsFromAssignedOrgs, action.payload);
        const result = yield take([userLocationsActions.cancel.type, userLocationsActions.initCurrentLocationsFromAssignedOrgsSuccess.type]);
        if (result.type === userLocationsActions.cancel.type) yield cancel(task);
    });
}

export function* userLocationsSaga() {
    yield all([
        call(onFetchUserLocationsStart),
        call(onSaveUserLocationsAccess),
        call(onFetchUserLocationStart),
        call(onFetchingLocationsFromAssignedOrgsStart),
        call(onAddingLocationsFromSelectedOrgsStart),
    ]);
}
