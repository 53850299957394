import store from 'store/store';
import { formControlActions } from './formControlSlice';

const changeFieldValue = function (formId, fieldName, value = null, required, error) {
    store.dispatch(
        formControlActions.setFieldValue({
            formId,
            fieldName,
            value,
        })
    );

    required &&
        value !== '' &&
        error &&
        store.dispatch(
            formControlActions.setFieldError({
                formId,
                fieldName,
                error: false,
            })
        );
};
export const changeHandler = function (e, newValue) {
    const { lookup, formId, fieldName, error, required, type } = this;

    let value =
        newValue !== undefined ? (lookup.displayExpr ? (lookup.valueExpr ? newValue?.[lookup.valueExpr] : newValue) : newValue) : e.target.value;

    if (value !== '') {
        if (type === 'number') {
            value = +value; // Convert to number
        } else if (type === 'number-only') {
            value = value.replace(/\D/g, ''); // Remove non-digit characters
        }
    }

    changeFieldValue(formId, fieldName, value, required, error);
};

export const inputValueChangeHandler = function (_, newValue) {
    const { lookup, formId, fieldName, error, required, type } = this;
    if (!lookup.displayExpr) {
        const value = type === 'number' ? +newValue : newValue;

        changeFieldValue(formId, fieldName, value, required, error);
    }
};

export const blurHandler = function blurHandler(e) {
    const { required, touched, error, formId, fieldName } = this;
    let value = e.target.value;
    if (fieldName.includes('[]')) {
        value = value.split(',').map((value) => {
            return value.replace(/  +/g, ' ').trim();
        });
    } else {
        value = e.target.value.replace(/  +/g, ' ').trim();
    }
    value !== e.target.value && changeFieldValue(formId, fieldName, value, required, error);

    required &&
        e.target.value === '' &&
        !error &&
        store.dispatch(
            formControlActions.setFieldError({
                formId,
                fieldName,
            })
        );

    required &&
        !touched &&
        store.dispatch(
            formControlActions.setFieldTouched({
                formId,
                fieldName,
            })
        );
};

export const displayFieldCustomError = function (formId, fieldName, errorText) {
    store.dispatch(
        formControlActions.setFieldError({
            formId,
            fieldName,
            error: errorText,
        })
    );

    store.dispatch(
        formControlActions.setFieldTouched({
            formId,
            fieldName,
        })
    );
};

export const getFieldConfig = function ({
    formId,
    fieldName,
    label,
    required,
    type,
    helperText = 'This field is required',
    value,
    error,
    touched,
    initialValue,
    disabled,
    inputProps,
}) {
    const customLabel = required ? label + '*' : label;
    const customHelperText = typeof error === 'string' ? error : helperText;
    return {
        formId,
        fieldName,
        label: customLabel,
        required,
        type,
        changeHandler,
        blurHandler,
        inputValueChangeHandler,
        helperText: customHelperText,
        value,
        error,
        touched,
        initialValue,
        disabled,
        inputProps,
    };
};
