import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';

import { SnackbarProvider } from 'notistack';
import { snackBarGeneralConfiguration } from 'config';
import ErrorBoundary from 'components/errorBoundaries/MainEB';
import Notifier from 'components/UI/notifications/Notifier';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ThemeProvider } from '@mui/material/styles';
import { DEFAULT_THEME } from 'utils/themeHelper.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <ErrorBoundary>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemeProvider theme={DEFAULT_THEME}>
                    <SnackbarProvider
                        maxSnack={snackBarGeneralConfiguration.maxSnack}
                        autoHideDuration={snackBarGeneralConfiguration.autoHideDuration}
                        preventDuplicate={true}
                    >
                        <App />
                        <Notifier />
                    </SnackbarProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </Provider>
    </ErrorBoundary>
    // </React.StrictMode>
);
