import { Box, Stack, TableBody, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { CustomBodyCell, CustomBodyRow, CustomHeader, CustomHeaderCell, CustomTable } from './HelpTableStyled';

const HelpTable = ({ title, rows = [] }) => (
    <CustomTable>
        <CustomHeader>
            <TableRow>
                <CustomHeaderCell isTitle={true}>{title || 'Actions'}</CustomHeaderCell>
                <CustomHeaderCell>Description</CustomHeaderCell>
            </TableRow>
        </CustomHeader>
        <TableBody>
            {rows.map((row, index) => (
                <CustomBodyRow key={index}>
                    <CustomBodyCell isTitle={true} hideBottomLine={index === rows.length - 1}>
                        <Stack direction={row.reversed ? 'row-reverse' : 'row'} spacing={1} justifyContent="start" alignItems={'center'}>
                            <span>{row.action}</span>
                            <Box>{row.icon}</Box>
                        </Stack>
                    </CustomBodyCell>
                    <CustomBodyCell hideBottomLine={index === rows.length - 1}>{row.description}</CustomBodyCell>
                </CustomBodyRow>
            ))}
        </TableBody>
    </CustomTable>
);

HelpTable.propTypes = {
    title: PropTypes.string,
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            action: PropTypes.string,
            description: PropTypes.string,
            icon: PropTypes.element,
            reversed: PropTypes.bool,
        })
    ),
};

export default HelpTable;
