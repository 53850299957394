import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fetchingUserLocationsInProgress: false,
    fetchingUserLocationsError: null,
    userLocationsList: [],

    user: null,
    locations: [],
    locationsBeforeSave: [],
    fetchFailed: false,
    saveUserLocationInprogress: false,
    fetchingUserLocationsListInprogress: false,

    locationsFromSelectedOrganizations: [],
    fetchLocationsFromAssignedOrgsFailed: false,
    isFetchingLocationsFromAssignedOrgs: false,

    isAddingLocationsFromSelectedOrgs: false,
    addLocationsFromSelectedOrgsFailed: false,

    locationsAccessPopupVisible: false,
};

const userLocationsSlice = createSlice({
    name: 'userLocations',
    initialState,
    reducers: {
        fetchUserLocationsStart(state) {
            state.fetchingUserLocationsInProgress = true;
            state.fetchingUserLocationsError = null;
        },
        fetchUserLocationsSuccess(state, { payload }) {
            state.fetchingUserLocationsInProgress = false;
            state.userLocationsList = payload;
        },
        fetchUserLocationsError(state, { payload: error }) {
            state.fetchingUserLocationsInProgress = false;
            state.fetchingUserLocationsError = error;
        },

        //init list of locations from assigned organizations
        initLocationsFromAssignedOrgsStart(state) {
            state.fetchLocationsFromAssignedOrgsFailed = false;
            state.isFetchingLocationsFromAssignedOrgs = true;
        },
        initCurrentLocationsFromAssignedOrgsSuccess(state, { payload: newLocations }) {
            state.fetchLocationsFromAssignedOrgsFailed = false;
            state.isFetchingLocationsFromAssignedOrgs = false;
            state.locationsFromSelectedOrganizations = [...state.locationsFromSelectedOrganizations, ...newLocations];
        },
        initCurrentLocationsFromAssignedOrgsFailed(state) {
            state.fetchLocationsFromAssignedOrgsFailed = true;
            state.isFetchingLocationsFromAssignedOrgs = false;
            state.locationsFromSelectedOrganizations = [];
        },

        // addLocationsFromSelectedOrgs
        addLocationsFromSelectedOrgsStart(state, { payload: newLocations }) {
            state.isAddingLocationsFromSelectedOrgs = true;
            state.addLocationsFromSelectedOrgsFailed = false;
        },
        addLocationsFromSelectedOrgsSuccess(state, { payload: newLocations }) {
            state.isAddingLocationsFromSelectedOrgs = false;
            state.addLocationsFromSelectedOrgsFailed = false;
            state.locationsFromSelectedOrganizations = [...state.locationsFromSelectedOrganizations, ...newLocations];
            state.locationsBeforeSave = [...state.locationsBeforeSave, ...newLocations];
        },
        addLocationsFromSelectedOrgsFailed(state) {
            state.isAddingLocationsFromSelectedOrgs = false;
            state.addLocationsFromSelectedOrgsFailed = true;
        },

        unselectOrganizations(state, { payload: removedOrgs }) {
            state.locationsFromSelectedOrganizations = state.locationsFromSelectedOrganizations.filter(
                (location) => !removedOrgs.map((org) => org.id).includes(location.organization.id)
            );
            state.locationsBeforeSave = state.locationsBeforeSave.filter(
                (location) => !removedOrgs.map((org) => org.id).includes(location.organization.id)
            );
        },

        resetOrganizationLocations(state) {
            state.locationsFromSelectedOrganizations = [];
        },
        setLocationsAccessPopupVisible(state, { payload }) {
            state.locationsAccessPopupVisible = payload;
        },
        saveUserLocationsStart(state) {
            state.user = null;
            state.locations = [];
            state.locationsBeforeSave = [];
            state.saveUserLocationInprogress = true;
        },
        saveUserLocationsEnd(state) {
            state.saveUserLocationInprogress = false;
        },
        fetchUserLocationsListStart(state) {
            state.fetchFailed = false;
            state.fetchingUserLocationsListInprogress = true;
        },
        fetchUserLocationsListSuccess(state, { payload: userLocations }) {
            state.fetchFailed = false;
            state.fetchingUserLocationsListInprogress = false;
            state.user = userLocations.user;
            if (userLocations.location) {
                state.locations = userLocations.location;
                state.locationsBeforeSave = userLocations.location;
            }
        },
        fetchUserLocationsListFailed(state) {
            state.user = null;
            state.locations = [];
            state.locationsBeforeSave = [];
            state.fetchFailed = true;
            state.fetchingUserLocationsListInprogress = false;
        },
        setLocationsBeforeSave(state, { payload: locations }) {
            state.locationsBeforeSave = locations;
        },
        setUser(state, { payload: user }) {
            state.user = user;
        },
        cancel(state) {
            state.organizationBeforeSave = null;
            state.locationsBeforeSave = state.locations;
            state.locations = [];
            state.locationsFromSelectedOrganizations = [];
        },
    },
});

export const userLocationsActions = userLocationsSlice.actions;
export const userLocationsReducer = userLocationsSlice.reducer;
