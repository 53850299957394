import React from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import UserListDropDown from '../userList/UserListDropDown';
import PropTypes from 'prop-types';
import { IconButton, Stack } from '@mui/material';

import { UserRolesTypography, CustomHelpOutlineIcon } from './UserNavigation.styles';

export default function UserNavigation({ user, onClickHelp }) {
    return (
        <Stack direction="row" gap={2} justifyContent="center" alignItems="center">
            <NotificationsNoneIcon />
            <IconButton onClick={onClickHelp}>
                <CustomHelpOutlineIcon />
            </IconButton>
            <div>
                <UserRolesTypography>Hi {user?.username}</UserRolesTypography>
            </div>
            <UserListDropDown />
        </Stack>
    );
}

UserNavigation.propTypes = {
    user: PropTypes.object,
    onClickHelp: PropTypes.func,
};
