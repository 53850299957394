import { styled, Table, TableHead, TableCell, TableRow, Box } from '@mui/material';

export const CustomTable = styled(Table)({
    textAlign: 'left',
    borderWidth: '0px',
    borderSpacing: '0px',
    tableLayout: 'fixed',
});

export const CustomHeader = styled(TableHead)({
    background: 'var(--color-light-grey)',
});

export const CustomHeaderCell = styled(TableCell)(({ isTitle }) => ({
    color: '#4f4f4f',
    fontSize: '14px',
    fontWeight: 700,
    borderBottom: 'none',
    width: isTitle ? '40%' : '100%',
    paddingLeft: isTitle ? '1rem' : '0',
}));

export const CustomBodyRow = styled(TableRow)({
    height: '3rem',
});

export const CustomBodyCell = styled(TableCell)(({ hideBottomLine, isTitle }) => ({
    borderBottom: hideBottomLine ? 'none' : '1px solid #e0e0e0',
    width: isTitle ? '30%' : '100%',
    paddingLeft: isTitle ? '1rem' : '0',
    fontWeight: 400,
    color: '#4f4f4f',
    fontSize: '15px',
}));
