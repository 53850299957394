import { Box, styled, Typography } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';

export const SettingsIcon = styled(SettingsOutlinedIcon)({
    fill: '#2d9cdb',
});

export const AddIcon = styled(AddCircleOutlinedIcon)({
    fill: 'var(--color-green)',
});

export const LogIcon = styled(RestoreOutlinedIcon)({
    fill: '#2f80ed',
});

export const Alert = styled(Box)(({ color = '' }) => ({
    display: 'flex',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    backgroundColor: color || 'var(--color-light-grey)',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const AlertLetter = styled(Typography)(({ color }) => ({
    fontSize: '10px',
    color: color || '#181D1F',
    marginTop: '0.1rem',
}));
