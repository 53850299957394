export const transformAuthorizedCompany = (data) => {
    return Object.values(
        data.reduce((obj, el) => {
            const accessPointList = el.monitoringObject?.id && {
                ...el.monitoringObject,
                objectName: `${el.monitoringObject?.objectType} - ${el.monitoringObject?.name}`,
            };
            const modifiedAuthorizedCompany = {
                ...el,
                id: el.authorizedOrganization.id,
                timestamp: `${el.created.slice(0, 10)} ${el.created.slice(11, 16)}`,
                phoneNumber: el.phoneNumber,
                email: el.email,
                name: el.authorizedOrganization.name,
                clientType: el.authorizedOrganization?.clientType,
                commonName: el.authorizedOrganization?.commonName,
                industryType: el.authorizedOrganization?.industryType,
                position: el.authorizedOrganization?.position,
                address: el.authorizedOrganization?.address,
                alerts: !!el.authType?.color,
                authorizedBy: '',
                authorizedByInfo: el.authorizedBy,
                note: el.note || '',
                authTypes: [],
                organization: el.organization ?? el.location?.organization,
                accessPoint: el.monitoringObject?.id,
                accessPointList,
                type: el.type,
            };

            const updatedAuthType = {
                ...modifiedAuthorizedCompany.authType,
                authTypeId: el.id,
                authorizedBy: modifiedAuthorizedCompany.authorizedByInfo,
                note: modifiedAuthorizedCompany.note,
                modified: modifiedAuthorizedCompany.modified,
                limitedPeriodAccess: {
                    accessType: el.accessType,
                    startTime: el.startTime,
                    endTime: el.endTime,
                    weekdays: el.weekdays,
                    periodType: el.periodType,
                },
            };

            modifiedAuthorizedCompany.authType = updatedAuthType;
            modifiedAuthorizedCompany.authTypes = [updatedAuthType];

            if (obj[modifiedAuthorizedCompany.id]) {
                obj[modifiedAuthorizedCompany.id].authTypes = [...obj[modifiedAuthorizedCompany.id].authTypes, updatedAuthType];
            } else {
                obj[modifiedAuthorizedCompany.id] = { ...modifiedAuthorizedCompany };
            }
            return obj;
        }, {})
    );
};

export const transformPayload = (data, isUpdated) => (isUpdated ? updatePayload(data) : createPayload(data));

const createPayload = (data) => {
    return {
        organization: {
            name: data.name,
            address: data.address,
            industryType: data.industryType,
            clientType: 'NON_CLIENT',
        },
        location: {
            id: data.locationId,
        },
        accessPoint: {
            id: data.accessPoint,
        },
        email: data.email,
        relationType: data.relationType,
        phoneNumber: data.phoneNumber,
        authorizationTypes: data.authorizationTypes,
    };
};

const updatePayload = (data) => {
    return (data.authorizationTypes || []).map((item) => {
        return {
            ...item,
            ...{
                id: item.id,
                authorizedOrganization: {
                    id: data?.id,
                },
                location: {
                    id: data.locationId,
                },
                monitoringObject: {
                    id: data.accessPoint,
                },
                email: data.email,
                relationType: data.relationType,
                phoneNumber: data.phoneNumber,
            },
        };
    });
};
