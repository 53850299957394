import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fetchingLocationsInProgress: false,
    fetchingLocationsError: null,
    locationsList: [],
    selectedLocationId: null,
    selectedLocation: null,
};

const globalLocationSlice = createSlice({
    name: 'globalLocation',
    initialState,
    reducers: {
        fetchLocationsStart(state) {
            state.fetchingLocationsInProgress = true;
            state.fetchingLocationsError = null;
        },
        fetchLocationsSuccess(state, { payload: locationsList }) {
            state.fetchingLocationsInProgress = false;
            state.locationsList = locationsList;
        },
        fetchLocationsError(state, { payload: error }) {
            state.fetchingLocationsInProgress = false;
            state.fetchingLocationsError = error;
        },
        setSelectedLocationId(state, { payload: selectedLocationId }) {
            // Only set selected location id if it is in the locations list
            //   to prevent setting invalid location id
            const foundLocation = state.locationsList.find((location) => location.id === selectedLocationId);
            if (!foundLocation) {
                return;
            }
            state.selectedLocationId = selectedLocationId;
        },
    },
});

export const { fetchLocationsStart, fetchLocationsSuccess, fetchLocationsError, setSelectedLocationId } = globalLocationSlice.actions;

export const globalLocationReducer = globalLocationSlice.reducer;
