import { ListItemButton, Icon } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';
import classes from './Drawer.module.css';

const DrawerItem = ({ route, onClick }) => {
    const handleClick = (event) => {
        if (route.items) {
            // Prevent default behavior if the route has child items
            event.preventDefault();
        }
        onClick(event);
    };

    return (
        <ListItemButton component={Link} to={route.path} onClick={handleClick} className={classes.item}>
            <div className={`${classes.item} ${route.disabled && classes.item__disabled}`}>
                <Icon disabled={route.disabled}>{route.Icon && <route.Icon />}</Icon>

                <div>{route.text}</div>
                {route.items && <ArrowForwardIosIcon className={classes.arrow} />}
            </div>
        </ListItemButton>
    );
};

export default DrawerItem;

DrawerItem.propTypes = {
    route: PropTypes.object,
    onClick: PropTypes.func,
};
