import { createSelector } from '@reduxjs/toolkit';

export const organizationListSlice = (state) => state.organizationList;

export const selectAuthoriziedfetching = createSelector([organizationListSlice], (slice) => slice.submittingFormInProgress);
export const selectAuthoriziedErrors = createSelector([organizationListSlice], (slice) => slice.submittingFormError);
export const selectAuthoriziedItemFormStatus = createSelector([organizationListSlice], (slice) => slice.formStatus);
export const selectBeforeEditData = createSelector([organizationListSlice], (slice) => slice.beforeEditData);
export const selectRemovePopupVisible = createSelector([organizationListSlice], (slice) => slice.removePopupVisible);
export const selectAddPopupVisible = createSelector([organizationListSlice], (slice) => slice.addPopupVisible);
export const selectMasterList = createSelector([organizationListSlice], (slice) => slice.masterList);
export const selectMasterListItem = createSelector([organizationListSlice], (slice) => slice.selectedMasterList);
export const selectOrganizations = createSelector([organizationListSlice], (slice) => slice.organizations);
export const selectSubmittingFormInProgress = createSelector([organizationListSlice], (slice) => slice.submittingFormInProgress);
