import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchMySSOConfiguration } from 'services/ssoConfigurationService';
import { ssoConfigurationActions } from './ssoConfigurationSlice';

export function* fetchSSOConfiguration() {
    try {
        const data = yield fetchMySSOConfiguration();
        yield put(ssoConfigurationActions.fetchSSOConfigurationSuccess(data));
    } catch (err) {
        yield put(ssoConfigurationActions.fetchSSOConfigurationSuccess({ enableSso: false }));
    }
}

export function* onFetchingSSOConfigurationStart() {
    yield takeEvery(ssoConfigurationActions.fetchSSOConfigurationStart.type, fetchSSOConfiguration);
}

export function* ssoConfigurationSaga() {
    yield all([call(onFetchingSSOConfigurationStart)]);
}
