import { createSlice } from '@reduxjs/toolkit';
import { sub, startOfDay, endOfDay } from 'date-fns';
import { ACCESS_POINT_RECORDS_TABLE_ID } from '../constants/accessPointRecordTableConstant';

const now = startOfDay(new Date());
const initialState = {
    fetchingAccessPointRecordsInProgress: false,
    fetchingAccessPointRecordsError: null,
    accessPointRecords: {},
    fetchingAccessPointRecordsColumnInProgress: false,
    fetchingAccessPointRecordsColumnError: null,
    accessPointRecordsColumnList: [],
    accessPointRecordsFormFieldInProgress: false,
    accessPointRecordsFormFieldError: null,
    accessPointRecordsFormFieldList: [],
    filterDateRange: {
        startDate: sub(now, { months: 1 }).toISOString(),
        endDate: endOfDay(now).toISOString(),
    },
    searchText: null,
    allFilter: [],
    selectedFilter: [],
    removingAccessPointRecordInProgress: false,
    removingAccessPointRecordError: null,
};

const accessPointRecordsSlice = createSlice({
    name: 'accessPointRecords',
    initialState,
    reducers: {
        fetchAccessPointRecordsStart(state) {
            state.fetchingAccessPointRecordsInProgress = true;
            state.fetchingAccessPointRecordsError = null;
        },
        fetchAccessPointRecordsSuccess(state, { payload: { data, pageNum, tableId, append } }) {
            state.fetchingAccessPointRecordsInProgress = false;
            let allTablesData = state.accessPointRecords;

            if (append) {
                allTablesData[tableId][pageNum] = { data };
            } else {
                allTablesData[tableId] = {
                    [pageNum]: { data },
                };
            }
        },
        fetchAccessPointRecordsError(state, { payload: error }) {
            state.fetchingAccessPointRecordsInProgress = false;
            state.fetchingAccessPointRecordsError = error;
        },
        fetchAccessPointRecordsColumnStart(state) {
            state.fetchingAccessPointRecordsColumnInProgress = true;
            state.fetchingAccessPointRecordsColumnError = null;
        },
        fetchAccessPointRecordsColumnSuccess(state, { payload: accessPointRecordsColumnList }) {
            state.fetchingAccessPointRecordsColumnInProgress = false;
            state.accessPointRecordsColumnList = accessPointRecordsColumnList;
        },
        fetchAccessPointRecordsColumnError(state, { payload: error }) {
            state.fetchingAccessPointRecordsColumnInProgress = false;
            state.fetchingAccessPointRecordsColumnError = error;
        },
        fetchAccessPointRecordsFormFieldStart(state) {
            state.fetchingAccessPointRecordsFormFieldInProgress = true;
            state.fetchingAccessPointRecordsFormFieldError = null;
            state.accessPointRecordsFormFieldList = [];
        },
        fetchAccessPointRecordsFormFieldSuccess(state, { payload: accessPointRecordsFormFieldList }) {
            state.fetchingAccessPointRecordsFormFieldInProgress = false;
            state.accessPointRecordsFormFieldList = accessPointRecordsFormFieldList;
        },
        fetchAccessPointRecordsFormFieldError(state, { payload: error }) {
            state.fetchingAccessPointRecordsFormFieldInProgress = false;
            state.fetchingAccessPointRecordsFormFieldError = error;
        },
        setFilterDateRange(state, { payload: { startDate, endDate } }) {
            state.filterDateRange = {
                startDate,
                endDate,
            };
        },
        setSearchText(state, { payload: text }) {
            state.searchText = text;
        },
        setSelectedFilter(state, { payload: filter }) {
            state.selectedFilter = filter;
        },
        setAllFilter(state, { payload: filters }) {
            state.allFilter = filters;
        },
        removeAccessPointRecordsStart(state) {
            state.removingAccessPointRecordInProgress = true;
            state.removingAccessPointRecordError = null;
        },
        removeAccessPointRecordsSuccess(state, { payload: locationId }) {
            const pageNum = 0;
            const accessPointRecordsTable = state.accessPointRecords[ACCESS_POINT_RECORDS_TABLE_ID][pageNum].data;
            state.accessPointRecords[ACCESS_POINT_RECORDS_TABLE_ID][pageNum] = {
                data: accessPointRecordsTable.filter((record) => record.id !== locationId),
            };
            state.removingAccessPointRecordInProgress = false;
        },
        removeAccessPointRecordsError(state, { payload: error }) {
            state.removingAccessPointRecordInProgress = false;
            state.removingAccessPointRecordError = error;
        },
    },
});

export const {
    fetchAccessPointRecordsStart,
    fetchAccessPointRecordsSuccess,
    fetchAccessPointRecordsError,
    fetchAccessPointRecordsColumnStart,
    fetchAccessPointRecordsColumnSuccess,
    fetchAccessPointRecordsColumnError,
    fetchAccessPointRecordsFormFieldStart,
    fetchAccessPointRecordsFormFieldSuccess,
    fetchAccessPointRecordsFormFieldError,
    setFilterDateRange,
    setSearchText,
    setSelectedFilter,
    setAllFilter,
    removeAccessPointRecordsStart,
    removeAccessPointRecordsSuccess,
    removeAccessPointRecordsError,
} = accessPointRecordsSlice.actions;

export const accessPointRecordsActions = accessPointRecordsSlice.actions;
export const accessPointRecordsReducer = accessPointRecordsSlice.reducer;
