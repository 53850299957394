import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isSidePanelOpen: false,
};

const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
        setIsSidePanelOpen(state, { payload: isOpen }) {
            state.isSidePanelOpen = isOpen;
        },
    },
});

export const sharedActions = sharedSlice.actions;
export const sharedReducer = sharedSlice.reducer;
