import SvgIcon from '@mui/material/SvgIcon';

const AppointmentIcon = () => {
    return (
        <SvgIcon>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="25" height="25" fill="url(#pattern0_1729_1137)" />
                <defs>
                    <pattern id="pattern0_1729_1137" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlinkHref="#image0_1729_1137" transform="scale(0.0111111)" />
                    </pattern>
                    <image
                        id="image0_1729_1137"
                        width="90"
                        height="90"
                        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE6UlEQVR4nO2cz4scRRTHKwd/HSUiuv4AkUU8SKKioqCTEIV1u/v7rT7MXyCuEDRiwF0PoibgSUnISSPi1ZM/cBHx6kVzCZsYvXvwYnQTMAfX6I4+tpVhM93TPd1d1dXzvvBgmamtrvpM9auq16/aGJVKpVKpVCqVSqVSqVQqlRkMBjcCeBnAGZJXSI56aldIfgfgyNLS0g1Of/o0Te8kea4DEEYuDcCG9N3ZSJ5HyByD7WRki7vw3Vn6t5dcgD7TgY6OPNu3LkD/3oGOjnyaMGgddN7FTU9FX/1V0FTQbUhHtCMpaEdS0I6koB1JQTuSgnYkBe1ICtqRFPS8gp43MwqaCpodGIk6oukfnroO+gerPpoK2vuoo45oegelroP+IfbKRwPYIrkKYEGM5Fr2WRDlgwFNcm1C3WuhlDehgE7T9PbddS8vL98WSnkTkOtY2F13dss2Ul4g7S4vMMfqO0/yIwCvAHjaGLNnQnf3kDyUpcF9QPJCcKA54VYF8FpT5eW73eWttc8COJokyT2z9l/+N0mSF01AI3orm3CqTFaly5O8CuBNkneZENXgiB61bQDWrbX3mhDlGx7L2U9JkjxjQlYAo/gLa+1eE7o6DvmNnNXDNZKsfQAHARwH8BmAH0luAvgz8/2bJH+Q70geI3nA6RmWjgL+m+ThMu231j4sSzUAl2e4ziUAp+M4fmguQZN8blq7BQ6Arxu85lck9zsH7TIWYa3dm62hZQn3dlF7h8PhTSRPAfirhR9Yrn9SDlA5A+0pFnGoyCcDuC/bAbZ+UiuKosWGEP/fuc7EIooUx/EjAH5pG/IY7N9IPmGakq/YRVoBNIDHfBxqkmtaax917jqajF1wQvkCd/Gra8hjdrERN+IrdoGd9e3rcRzfXTTxufDJJUb2Ru0JsgOd+LCgbadq1LsF4FVxUVk4dXVK0GqanQgZ9La19v6CdXKdJdxqxVXQNLuapum+IEED+CavXXU3I1VXQSXb+2WQoEm+ULCtrlV3S/3dBvBgcKBtTmw5e8zURdBi74UG+ueCKNzlDoPeHA6H1wcT6yD58aT2SKiziR+yzYFlrR0EE+uw1q7kgD7eddAk3wppC35rTns+DwD0p8GAjuP4lkntyZ6MlN6MmIZUcVNzofIFfMU6bM5zwJJxjWs2I02p5Kbm4iwVe4l1DHNm7jIjatLd05SK7sIx+6NyxQ35rFFVW1lZua6LoKMouqNXoKMourmG6ygVYp1FU9xj866jbUty8uXKTob/uakm4GbXLbP2n30y9PU6NgAHA17efRLMCwYBPJ8D4ljXQUuy5Sygj3gCfToHxIEAQD9VGXQWxNnwAPp8QXsudRW0PB2fKagkklf7eoC9LUupSe2R0d67MOn4SJK3zsoLUR1OkIfzHmN1FPR2q2ljPsSdXLg6t/jCjJuRojrXTd9Ecr88EK0BpmqsZppJWx4wfRSAkzVGX9UzMtPqe8f0VYOd1+KfreuvG7Bzksxj+qwoihYltuALsiRWBntAqaok0dBXkqNksZp5Uuwhbdda+7iZR0VRtOhoY3V2btzFlAnyRM2lX9HRindbOVoRqtI03Se5cNlubdRAqtd6b9fJTUhy4QC8n50jrOyHJXbRu221aVESUZPwpSS3SN6FPAWRx2JZaoKY/P29BO2zMk/OHIX794L/AGgP5/sA4eU1AAAAAElFTkSuQmCC"
                    />
                </defs>
            </svg>
        </SvgIcon>
    );
};

export default AppointmentIcon;
