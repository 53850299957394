import { axiosLasta } from 'config';
import { toQueryString } from 'utils/queryStringHelpers';

export const fetchOrganizationLocationsByOrganizationId = async (id) => {
    const response = await axiosLasta.get(`/location/organization/${id}`, {
        params: {
            status: true,
        },
    });
    const { data } = response;
    return data;
};

export const fetchOrgAssignableUserList = async (organizationId) => {
    const payload = toQueryString({ organizationId: organizationId });
    const response = await axiosLasta(`/organization/assignable-users?${payload}`);
    const { data } = response;
    return data;
};

export const fetchOrganizationById = async (organizationId) => {
    const response = await axiosLasta(`/organization/id/${organizationId}`);
    const { data } = response;
    return data;
};
