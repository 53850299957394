import { FormControl, Stack, TextField } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import useAccessPointRecord from 'components/accessPointRecords/hooks/useAccessPointRecord';
import useGlobalLocation from 'components/globalLocationSelector/hooks/useGlobalLocation.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LightAutocomplete } from './GlobalLocationSelector.styled.js';

const KEY_ENTER = 'Enter';

const GlobalLocationSelector = () => {
    const { locationsList, fetchLocations, fetchingLocationsInProgress, selectedLocationId, setSelectLocationId } = useGlobalLocation();
    const { dispatchSetSelectedFilter } = useAccessPointRecord();
    const [inputValue, setInputValue] = useState('');

    const locationOpts = useMemo(() => {
        const options =
            locationsList?.map((location) => ({
                value: location.id,
                label: location.yardName,
            })) || [];
        return options;
    }, [locationsList]);

    const handleLocationChange = (_, newValue) => {
        if (newValue === null) return;
        setSelectLocationId(newValue?.value || '');
        dispatchSetSelectedFilter({ criteria: [] });
    };

    const handleInputChange = (_, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === KEY_ENTER) {
                const matchingOption = locationOpts.find((option) => option.label.toLowerCase() === inputValue.toLowerCase());
                if (matchingOption) {
                    setSelectLocationId(matchingOption.value);
                    dispatchSetSelectedFilter({ criteria: [] });
                }
            }
        },
        [inputValue, locationOpts, setSelectLocationId, dispatchSetSelectedFilter]
    );

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    return (
        <Stack direction="row" gap={2} flexGrow={1}>
            <FormControl>
                <LightAutocomplete
                    options={locationOpts}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Location"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            disabled={fetchingLocationsInProgress}
                            onKeyDown={handleKeyDown}
                        />
                    )}
                    value={locationOpts.find((opt) => opt.value === selectedLocationId) || null}
                    onChange={handleLocationChange}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    clearIcon={inputValue ? <ClearIcon size="small" /> : null}
                />
            </FormControl>
        </Stack>
    );
};

export default GlobalLocationSelector;
