import { ACCESS_TYPE, DAY_FULL_TO_ABBR, PERIOD_ACCESS_TYPE } from 'components/UI/DynamicAlertRows/constants';
import { parse } from 'date-fns';
import { generateDefaultTimeRestrictions, DEFAULT_START_TIME } from 'utils/dateTimeHelpers';
const { timeRestrictions: defaultTimeRestrictions, start, end } = generateDefaultTimeRestrictions();

export const transformAuthorizedPersons = (data) => {
    return Object.values(
        data.reduce((obj, el) => {
            const accessPoint = el.monitoringObject?.id && {
                ...el.monitoringObject,
                objectName: `${el.monitoringObject.objectType} - ${el.monitoringObject.name}`,
            };

            const modifiedAuthorizedPerson = {
                ...el,
                id: el.authorizedPerson.id,
                timestamp: `${el.created.slice(0, 10)} ${el.created.slice(11, 16)}`,
                firstName: el.authorizedPerson.firstName,
                lastName: el.authorizedPerson.lastName,
                position: el.authorizedPerson.position,
                phoneNumber: el.authorizedPerson.phoneNumber,
                dl: el.authorizedPerson.dl,
                cdl: el.authorizedPerson.cdl,
                companyCardNumber: el.authorizedPerson.companyCardNumber,
                alerts: !!el.authType?.color,
                authorizedBy: '',
                authorizedByInfo: el.authorizedBy,
                note: el.note || '',
                authTypes: [],
                organization: el.authorizedPerson.organization,
                accessPoint,
                dateOfBirth: el.authorizedPerson.dateOfBirth,
            };

            const updatedAuthType = {
                ...modifiedAuthorizedPerson.authType,
                authTypeId: el.id,
                authorizedBy: modifiedAuthorizedPerson.authorizedByInfo,
                note: modifiedAuthorizedPerson.note,
                modified: modifiedAuthorizedPerson.modified,
                limitedPeriodAccess: {
                    accessType: el.accessType,
                    startTime: el.startTime,
                    endTime: el.endTime,
                    weekdays: el.weekdays,
                    periodType: el.periodType,
                },
            };

            modifiedAuthorizedPerson.authType = updatedAuthType;
            modifiedAuthorizedPerson.authTypes = [updatedAuthType];

            if (obj[modifiedAuthorizedPerson.id]) {
                obj[modifiedAuthorizedPerson.id].authTypes = [...obj[modifiedAuthorizedPerson.id].authTypes, updatedAuthType];
            } else {
                obj[modifiedAuthorizedPerson.id] = { ...modifiedAuthorizedPerson };
            }

            return obj;
        }, {})
    );
};

export const mapInitialAlertRows = (selectedOrganization) => {
    return (
        selectedOrganization?.authTypes?.map((authType, index) => {
            const { limitedPeriodAccess } = authType;
            const isLimitedAccess = limitedPeriodAccess?.periodType === PERIOD_ACCESS_TYPE.LIMIT;
            const timeRestrictions = (limitedPeriodAccess?.weekdays || []).reduce(
                (acc, day) => {
                    const mappedDay = DAY_FULL_TO_ABBR[day.day];
                    acc[mappedDay] = {
                        start: day.timeRanges?.[0]?.startTime ? parse(day.timeRanges[0].startTime, 'HH:mm', new Date()) : start,
                        end: day.timeRanges?.[0]?.endTime ? parse(day.timeRanges[0].endTime, 'HH:mm', new Date()) : end,
                        checked: !!day.timeRanges?.length,
                    };
                    return acc;
                },
                { ...defaultTimeRestrictions }
            );

            return {
                id: `row-${index}`,
                authType: { id: authType.id },
                authorizedBy: { id: authType.authorizedBy?.id },
                note: authType.note || '',
                isPeriodAccessVisible: false,
                accessType: limitedPeriodAccess?.accessType || ACCESS_TYPE.PERMANENT,
                limitedAccess: isLimitedAccess,
                startTime: limitedPeriodAccess?.startTime ? new Date(limitedPeriodAccess?.startTime) : DEFAULT_START_TIME,
                endTime: limitedPeriodAccess?.endTime ? new Date(limitedPeriodAccess?.endTime) : null,
                timeRestrictions,
            };
        }) || []
    );
};

export const createPeopleFormPayload = (values, type) => {
    return {
        clientPersonnel: {
            dateOfBirth: values.dateOfBirth,
            firstName: values.firstName,
            lastName: values.lastName,
            position: values.position,
            phoneNumber: values.phoneNumber,
            dl: values.dl,
            cdl: values.cdl,
            companyCardNumber: values.companyCardNumber,
            organization: {
                id: values.organization?.id ?? values.organization,
            },
        },
        location: {
            id: type.locationId,
        },
        accessPoint: {
            id: values.accessPoint?.id ?? values.accessPoint,
        },
        authorizationTypes: type.authorizationTypes,
    };
};

export const updatePeopleFormPayload = (values, type, authOrgId) => {
    const data = createPeopleFormPayload(values, type);
    return (data.authorizationTypes || []).map((item) => {
        return {
            id: authOrgId,
            ...item,
            ...{
                id: item.id,
                authorizedPerson: { id: authOrgId },
                location: data.location,
                monitoringObject: data.accessPoint,
            },
        };
    });
};
