import { styled } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';

export const LightAutocomplete = styled(Autocomplete)(({ theme }) => ({
    minWidth: '15rem',
    '& .MuiOutlinedInput-root': {
        color: theme.palette.primary.contrastText,
        '& fieldset': {
            borderColor: theme.palette.primary.contrastText,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.contrastText,
        },
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText,
    },
    '& .MuiFormLabel-root': {
        color: theme.palette.primary.contrastText,
        '&.Mui-focused': {
            color: theme.palette.primary.contrastText,
        },
    },
}));
