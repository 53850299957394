import { useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import {
    Container,
    SelectionContainer,
    SelectionStack,
    ContentContainer,
    SelectionButton,
    ArrowIcon,
    StepContainer,
    Header,
    OrderedList,
    ListItem,
} from './HelpInstructionStyled';

const HelpInstruction = ({ instructions, height }) => {
    const [selectedInstructionIndex, setSelectedInstructionIndex] = useState(0);

    if (!instructions || instructions.length === 0) {
        return null;
    }

    const getInstructionComponent = (selectedInstructionIndex) => {
        const { title, steps = [] } = instructions[selectedInstructionIndex];
        return (
            <StepContainer>
                <Header>{title}</Header>
                <OrderedList>
                    {steps.map((step, index) => (
                        <ListItem key={index}>
                            <Stack direction="row" spacing={0.5}>
                                {step}
                            </Stack>
                        </ListItem>
                    ))}
                </OrderedList>
            </StepContainer>
        );
    };

    return (
        <Container height={height}>
            <SelectionContainer>
                <SelectionStack spacing={2}>
                    {instructions.map((instruction, index) => (
                        <SelectionButton
                            key={index}
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                setSelectedInstructionIndex(index);
                            }}
                            selected={selectedInstructionIndex === index}
                            disableRipple
                        >
                            <Header>{instruction.title}</Header>
                            <ArrowIcon />
                        </SelectionButton>
                    ))}
                </SelectionStack>
            </SelectionContainer>
            <ContentContainer>{getInstructionComponent(selectedInstructionIndex)}</ContentContainer>
        </Container>
    );
};

HelpInstruction.propTypes = {
    instructions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            steps: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    height: PropTypes.string || PropTypes.number,
};

export default HelpInstruction;
