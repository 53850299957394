import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { featureFlagActions } from './featureFlagSlice';
import notificationsHandler from 'utils/notificationsHandler';
import { selectFeatureFlagData } from 'store/featureFlag/featureFlagSelector';
import { isEmpty } from 'lodash';
import { getFeatureFlagConfig } from 'services/featureFlagService';

function* getFeatureFlagData() {
    try {
        const featureFlagData = yield select(selectFeatureFlagData);
        if (!isEmpty(featureFlagData)) return;
        const featureFlagConfig = yield call(getFeatureFlagConfig);
        yield put(featureFlagActions.setFeatureFlagData(featureFlagConfig || {}));
    } catch (err) {
        yield call(notificationsHandler, {
            err,
            title: 'Error getting enabled sso data!',
        });
    }
}

function* onGetFeatureFlagData() {
    yield takeLatest(featureFlagActions.getFeatureFlagData.type, getFeatureFlagData);
}

export function* featureFlagSaga() {
    yield all([call(onGetFeatureFlagData)]);
}
