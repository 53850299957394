import { Box, Button, List, ListItemText, Stack, styled, Typography } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

export const Container = styled(Box)(({ height }) => ({
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    height: height || '12rem',
    width: '100%',
    alignItems: 'center',
}));

export const SelectionContainer = styled(Box)({
    position: 'relative',
    display: 'flex',
    height: '100%',
    flex: 2,
    alignItems: 'center',
});

export const SelectionStack = styled(Stack)({
    position: 'absolute',
    left: 0,
    right: '-10%',
});

export const SelectionButton = styled(Button)(({ selected }) => ({
    height: '2rem',
    width: '100%',
    textTransform: 'none',
    fill: 'var(--color-blue)',
    border: '1px solid #e0e0e0',
    justifyContent: 'space-between',
    '&': {
        backgroundColor: selected ? '#fafbff' : 'var(--color-white)',
    },
    '&&:hover': {
        backgroundColor: 'var(--color-white)',
    },
}));

export const ArrowIcon = styled(ArrowForwardIosRoundedIcon)({
    fill: 'var(--color-blue)',
    width: '1rem',
});

export const ContentContainer = styled(Box)({
    height: '100%',
    flex: 3,
    backgroundColor: '#fafbff',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    padding: '1rem 1rem 1rem 8%',
});

export const StepContainer = styled(Box)({
    width: '100%',
    height: '100%',
});

export const Header = styled(Typography)({
    fontWeight: 700,
    fontSize: '14px',
    textAlign: 'left',
    color: '#4f4f4f',
});

export const OrderedList = styled(List)({
    listStyleType: 'decimal',
    paddingLeft: '1rem',
});

export const ListItem = styled(ListItemText)({
    display: 'list-item',
    justifyContent: 'center',
    fontWeight: 400,
    color: '#4f4f4f',

    '& span': {
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
    },
});
