import PropTypes from 'prop-types';
import { SectionHeader, Description } from './HelpSectionStyled';
import HelpDivider from './HelpDividerStyled';

const HelpSection = ({ title, description, children, hasTopDivider }) => (
    <>
        {hasTopDivider && <HelpDivider />}
        <SectionHeader variant="h4">{title}</SectionHeader>
        {description && <Description hasBottomSpacing={true}>{description}</Description>}
        {children}
    </>
);

HelpSection.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    children: PropTypes.node,
    hasTopDivider: PropTypes.bool,
};

export default HelpSection;
