import { axiosLasta } from 'config';

export const fetchUserLocationForCurrentUser = async () => {
    const response = await axiosLasta(`/user-locations/current-user`);
    const { data } = response;
    return data;
};

export const fetchAllLocations = async () => {
    const response = await axiosLasta(`/location/all`);
    const { data } = response;
    return data;
};

export const fetchUserLocations = async () => {
    const response = await axiosLasta(`/user-locations`);
    const { data } = response;
    return data;
};

export const fetchUserLocationsById = async (id) => {
    const response = await axiosLasta(`/user-locations?userId=${id}`);
    const { data } = response;
    return data;
};

export const saveUserLocations = async (userLocations) => {
    const response = await axiosLasta.put(`/user-locations`, userLocations);
    const { data } = response;
    return data;
};
