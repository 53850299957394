import { createSelector } from '@reduxjs/toolkit';
import { fromIsoString } from 'utils/dateTimeHelpers.js';

export const selectFetchingAccessPointRecordsInProgress = (state) => state.accessPointRecordsList.fetchingAccessPointRecordsInProgress;
export const selectFetchingAccessPointRecordsError = (state) => state.accessPointRecordsList.fetchingAccessPointRecordsError;
export const selectFetchingAccessPointRecordsColumnInProgress = (state) => state.accessPointRecordsList.fetchingAccessPointRecordsColumnInProgress;
export const selectFetchingAccessPointRecordsColumnError = (state) => state.accessPointRecordsList.fetchingAccessPointRecordsColumnError;
export const selectAccessPointRecordsColumnList = (state) => state.accessPointRecordsList.accessPointRecordsColumnList;
export const selectFetchingAccessPointRecordsFormFieldInProgress = (state) =>
    state.accessPointRecordsList.fetchingAccessPointRecordsFormFieldInProgress;
export const selectFetchingAccessPointRecordsFormFieldError = (state) => state.accessPointRecordsList.fetchingAccessPointRecordsFormFieldError;
export const selectAccessPointRecordsFormFieldList = (state) => state.accessPointRecordsList.accessPointRecordsFormFieldList;
export const selectSearchText = (state) => state.accessPointRecordsList.searchText;
export const selectSelectedFilter = (state) => state.accessPointRecordsList.selectedFilter;
export const selectAllFilter = (state) => state.accessPointRecordsList.allFilter;
export const selectRemovingAccessPointRecordInProgress = (state) => state.accessPointRecordsList.removingAccessPointRecordInProgress;
export const selectRemovingAccessPointRecordError = (state) => state.accessPointRecordsList.removingAccessPointRecordError;

//Handle for data of table
export const selectAccessPointRecordsFilterDateRange = createSelector(
    (state) => state.accessPointRecordsList.filterDateRange,
    (filterDateRange) => ({
        startDate: fromIsoString(filterDateRange.startDate),
        endDate: fromIsoString(filterDateRange.endDate),
    })
);
export const selectAccessPointRecordsSlice = (state) => state.accessPointRecordsList;

export const selectAllTablesData = createSelector([selectAccessPointRecordsSlice], (tableControlSlice) => tableControlSlice.accessPointRecords);

export const selectAccessPointRecordsTableById = (id) =>
    createSelector([selectAllTablesData, (_, tableId = id) => tableId], function (allTablesData, tableId) {
        return allTablesData?.[tableId];
    });

export const selectAccessPointRecordsDataByTableId = (id) =>
    createSelector([selectAccessPointRecordsTableById(id)], function (table) {
        let tableData = [];
        if (table) {
            for (let page in table) {
                for (let transaction of table[page].data) {
                    tableData.push({ ...transaction });
                }
            }
        }
        return { tableData };
    });
