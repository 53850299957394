import { useSelector } from 'react-redux';
import { selectFeatureFlagData } from 'store/featureFlag/featureFlagSelector';

const useFeatureFlag = () => {
    const {
        appointmentEnabled: isAppointmentEnabled,
        clientRequestEnabled: isClientRequestEnabled,
        ssoenabled: isSsoEnabled,
        clientPersonnelEnabled: isClientPersonnelEnabled,
        inventoryEnabled: isInventoryEnabled,
    } = useSelector(selectFeatureFlagData);

    return {
        isSsoEnabled,
        isAppointmentEnabled,
        isClientRequestEnabled,
        isClientPersonnelEnabled,
        isInventoryEnabled,
    };
};

export default useFeatureFlag;
