import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DownloadPdf from 'components/icons/DownloadPdf';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import styles from './HelpDialog.module.css';

const HelpDialog = () => {
    const instructions = [
        {
            key: 'addNew',
            title: 'Adding a New Transaction Record',
        },
        {
            key: 'filter',
            title: 'Filtering Transaction Records',
        },
        {
            key: 'saveFilter',
            title: 'Saving Filters',
        },
    ];

    const [selectedInstruction, setSelectedInstruction] = useState(instructions[0].key);

    const getInstructionComponent = (key) => {
        switch (key) {
            case 'addNew':
                return renderAddNewInstruction();
            case 'filter':
                return renderFilterInstruction();
            case 'saveFilter':
                return renderSaveFilterInstruction();
            default:
                return renderAddNewInstruction();
        }
    };

    const renderDivider = () => <Divider className={styles['divider']} />;

    const renderAddNewInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Adding a New Transaction Record</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>Click on the</span>
                    <span>
                        <AddCircleOutlinedIcon className={`${styles['add-icon']} ${styles['inlined-icon']}`} />
                    </span>
                    <span>button.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Fill out the form with required information.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Click “Save” to add the new record.</span>
                </ListItemText>
            </List>
        </Box>
    );

    const renderFilterInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Filtering Transaction Records</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>Use the search input in the left sidebar to search all records.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Use column filters for a more detailed search.</span>
                </ListItemText>
            </List>
        </Box>
    );

    const renderSaveFilterInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Saving Filters</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>Use the filter options at the top of the table to apply the necessary filters.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Click on the “Save Filter“.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Enter a name for your new filter profile.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Click “Save”, and the filter now be available in the filter panel for future use.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>You can adjust the available filter profiles as needed.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <>
                        <span>After modifying the filter, click on “Save Filter” again.</span>
                        <br />
                        <span>▪ Select “Save changes” to update the current filter profile with the new settings.</span>
                        <br />
                        <span>▪ Select “Save as new filter” to create a new filter profile from the new changes.</span>
                    </>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Click on the “Remove“ to remove the profile filter.</span>
                </ListItemText>
            </List>
        </Box>
    );

    const renderOverviewSection = () => (
        <>
            <h4>OVERVIEW</h4>
            <p className={styles['description']}>Access and manage transaction records for all locations where you have permission. You can:</p>
            <List className={styles['unordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>View and apply filters to transaction records</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Save customized filtered views</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Add new transaction records or update existing ones</span>
                </ListItemText>
            </List>
        </>
    );

    const renderControlsSection = () => (
        <>
            <h4>CONTROLS</h4>
            <Table className={styles['controls-table']}>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <span>Add new</span>
                            <span>
                                <AddCircleOutlinedIcon className={`${styles['add-icon']} ${styles['inlined-icon']}`} />
                            </span>
                        </TableCell>
                        <TableCell>Add new record.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Modify record</span>
                            <span>
                                <MoreVertIcon size="small" className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Edit or remove the record’s information.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Download options</span>
                            <span>
                                <DownloadPdf className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Export current transaction records in preferred file format.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Save filter</span>
                            <span>
                                <SettingsOutlinedIcon className={`${styles['gear-icon']} ${styles['inlined-icon']}`} />
                            </span>
                        </TableCell>
                        <TableCell>Apply and save custom filters for future use.</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );

    const renderInstructionsSection = () => (
        <>
            <h4>INSTRUCTIONS</h4>
            <Box className={styles['instruction-container']}>
                <Box className={styles['instruction-title-container']}>
                    <Stack spacing={2}>
                        {instructions.map((instruction) => (
                            <Button
                                key={instruction.key}
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    setSelectedInstruction(instruction.key);
                                }}
                                className={`${styles['instruction-button']}
                                    ${selectedInstruction === instruction.key ? styles['instruction-button--selected'] : ''}`}
                                disableRipple
                            >
                                {instruction.title}
                                <ArrowForwardIosRoundedIcon className={styles['arrow-right']} />
                            </Button>
                        ))}
                    </Stack>
                </Box>
                <Box className={styles['instruction-content-container']}>{getInstructionComponent(selectedInstruction)}</Box>
            </Box>
        </>
    );

    const renderFooter = () => (
        <Stack className={styles['footer']}>
            <span>
                {`For further support, email us at `}
                <Link href="mailto:cs@birdseye.ca" underline="none">
                    cs@birdseye.ca
                </Link>
                {'.'}
            </span>
        </Stack>
    );

    return (
        <Stack className={styles['help-dialog-container']}>
            <Box className={styles['help-dialog-content-container']}>
                {renderOverviewSection()}
                {renderDivider()}
                {renderControlsSection()}
                {renderDivider()}
                {renderInstructionsSection()}
            </Box>
            {renderFooter()}
        </Stack>
    );
};

export default HelpDialog;
