import { put } from 'redux-saga/effects';
import { snackbarActions } from 'store/snackbars/snackbarsSlice';
import { action } from 'components/UI/buttons/NotificationDismissButton';
import store from 'store/store';
import { useSnackbar } from 'notistack';

function* notificationsHandler({ err, action, title, variant = 'error' }) {
    if (action)
        yield put(
            action({
                title: title,
                message: err.message,
                code: err.code,
            })
        );

    if (err?.response?.status === 401) return;
    const errorDetails = err ? `|| CODE: ${err.code}  ||  MESSAGE: ${err.message}` : '';

    yield put(
        snackbarActions.enqueueSnackbar({
            message: `${title?.toUpperCase()} ${errorDetails && errorDetails}`,
            options: {
                variant: variant,
            },
        })
    );
}

export function showNotification({ err, title, variant = 'error' }) {
    if (err?.response?.status === 401) return;
    const errorDetails = err ? `|| CODE: ${err.code}  ||  MESSAGE: ${err.message}` : '';

    store.dispatch(
        snackbarActions.enqueueSnackbar({
            message: `${title?.toUpperCase()} ${errorDetails && errorDetails}`,
            options: {
                variant: variant,
            },
        })
    );
}

export const useSnackbarNotification = (onSubmit) => {
    const { enqueueSnackbar } = useSnackbar();

    const showNotification = async (success, message, variant = 'info') => {
        enqueueSnackbar(message, { variant, action });
        if (success) onSubmit();
    };

    return showNotification;
};

export default notificationsHandler;
