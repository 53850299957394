import { USER_STATUS } from 'utils/constants.js';

export const mapUserLocations = (data) => {
    return data.map((item) => {
        const user = item.user;
        const locations = item?.location?.map((loc) => loc.yardName).join(', ') ?? 'Unknown';
        const organizations = item?.location?.map((loc) => loc.organization?.name) ?? ['Unknown'];
        const uniqueOrganizations = [...new Set(organizations)].join(', ');
        const uniqueRoles = [...new Set(user.userApplicationRoles.map((role) => formatRoleName(role.roleName)))];
        const titles = uniqueRoles.join(', ');
        return {
            id: user.id,
            username: user.username,
            title: titles,
            organization: uniqueOrganizations,
            locations: locations,
            status: user.active ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE,
            actions: '',
        };
    });
};

const formatRoleName = (roleName) => {
    return roleName
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};
