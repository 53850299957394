import { styled, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const UserRolesTypography = styled(Typography)({
    fontSize: '1rem',
    color: 'var(--color-white)',
});

const CustomHelpOutlineIcon = styled(HelpOutlineIcon)({
    color: 'var(--color-white)',
});

export { UserRolesTypography, CustomHelpOutlineIcon };
