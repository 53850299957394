import HelpContainer from 'components/help/HelpContainer';
import HelpInstruction from 'components/help/HelpInstruction';
import HelpSection from 'components/help/HelpSection';
import HelpTable from 'components/help/HelpTable';
import DownloadPdf from 'components/icons/DownloadPdf';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AddIcon, Alert, AlertLetter, LogIcon } from 'components/UI/dialogs/HelpDialogStyled';

const HelpDialog = () => {
    const overviewList = ['Add new organization to the authorized list', 'Update existing organization records', 'Assign or modify alert types'];

    const controls = [
        {
            action: 'Add new',
            icon: <AddIcon />,
            description: 'Add new organization to the authorized list.',
        },
        {
            action: 'Modify record',
            icon: <MoreVertIcon />,
            description: 'Edit or remove the organization information.',
        },
        {
            action: 'Download options',
            icon: <DownloadPdf />,
            description: 'Export the current list of authorized organization in preferred file format.',
        },
    ];

    const alerts = [
        {
            action: 'Default',
            icon: (
                <Alert color="#f2f2f2">
                    <AlertLetter>D</AlertLetter>
                </Alert>
            ),
            description: 'No special conditions. Normal facility entry and exit allowed.',
            reversed: true,
        },
        {
            action: 'Banned',
            icon: (
                <Alert color="#E6B0AA">
                    <AlertLetter color="#ffffff">B</AlertLetter>
                </Alert>
            ),
            description: 'Access denied. The individual is prohibited from entering the facility.',
            reversed: true,
        },
        {
            action: 'Privilege Organization',
            icon: (
                <Alert color="#A2D9CE">
                    <AlertLetter color="#ffffff">P</AlertLetter>
                </Alert>
            ),
            description: 'The organization has special access to the facility.',
            reversed: true,
        },
        {
            action: 'Specific Requests - Organization',
            icon: (
                <Alert color="#FAD7A0">
                    <AlertLetter color="#ffffff">S</AlertLetter>
                </Alert>
            ),
            description: 'Granted access for special trailer or cargo requests tied to the organization.',
            reversed: true,
        },
        {
            action: 'Specialized Pickups',
            icon: (
                <Alert color="#F9E79F">
                    <AlertLetter color="#ffffff">S</AlertLetter>
                </Alert>
            ),
            description: 'Authorized for specific trailer pickups within the facility.',
            reversed: true,
        },
        {
            action: 'Information',
            icon: (
                <Alert color="#D2B4DE">
                    <AlertLetter color="#ffffff">I</AlertLetter>
                </Alert>
            ),
            description: 'Extra protocols or information apply to this individual or organization.',
            reversed: true,
        },
        {
            action: 'Alert Note',
            icon: (
                <Alert color="#fff">
                    <AlertLetter color="#ffffff">A</AlertLetter>
                </Alert>
            ),
            description: 'Custom instruction.',
            reversed: true,
        },
    ];

    const instructions = [
        {
            title: 'Adding a New Organization',
            steps: [
                <>
                    <span>Click on the</span>
                    <span>
                        <AddIcon />
                    </span>
                    <span>button.</span>
                </>,
                'Fill out the record form with the required information.',
                'Click “Save” to add the new organization to the authorized list.',
            ],
        },
        {
            title: 'Filtering Alert Type',
            steps: [
                'Toggle the switches next to each alert type to filter the list of organizations.',
                'The list will automatically update based on the alert types you have toggled on or off.',
                'You can further refine the list by using the search bar at the top of the sidebar to filter by name, organization, or title.',
            ],
        },
        {
            title: 'View Changes Log',
            steps: [
                <>
                    <span>Click on the</span>
                    <span>
                        <LogIcon />
                    </span>
                    <span>icon.</span>
                </>,
                'The Changes Log section will appear at the bottom of the page, where you can view recent changes made to the authorized list.',
            ],
        },
    ];

    return (
        <HelpContainer
            width="52rem"
            overview="Control which organizations have facility access and the type of alert associated with them. You can:"
            overviewList={overviewList}
        >
            <HelpSection title="CONTROLS" hasTopDivider={true}>
                <HelpTable rows={controls} />
            </HelpSection>
            <HelpSection
                title="ALERTS"
                description="Set up specialized alerts that will be used by gate agents to control access rights for each organization:"
                hasTopDivider={true}
            >
                <HelpTable title="Alerts" rows={alerts} />
            </HelpSection>
            <HelpSection title="INSTRUCTIONS" hasTopDivider={true}>
                <HelpInstruction instructions={instructions} height={'13rem'} />
            </HelpSection>
        </HelpContainer>
    );
};

export default HelpDialog;
