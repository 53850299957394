import { styled, Typography } from '@mui/material';

export const SectionHeader = styled(Typography)({
    fontSize: '16px',
    fontWeight: 700,
    color: '#828282',
    margin: '1rem 0',
});

export const Description = styled(Typography)(({ hasBottomSpacing }) => ({
    lineHeight: '18.15px',
    marginTop: '0.5rem',
    marginBottom: hasBottomSpacing ? '0.5rem' : 0,
    fontSize: '15px',
    fontWeight: 400,
    color: '#4f4f4f',
}));
