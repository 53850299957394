import SvgIcon from '@mui/material/SvgIcon';

const InventoryIcon = () => {
    return (
        <SvgIcon>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                <rect width="30" height="30" fill="url(#pattern0_2424_734)" />
                <defs>
                    <pattern id="pattern0_2424_734" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlinkHref="#image0_2424_734" transform="scale(0.0111111)" />
                    </pattern>
                    <image
                        id="image0_2424_734"
                        width="90"
                        height="90"
                        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAABpElEQVR4nO3cy2rCUBSF4dCB0D5bWGuU53HYdNoL8QXt0HaSTlNChVLQJsdc3Cb/B2ciHnBvNPEstmYZAAAAAADAyuR5/iDpTdKn7SbqkvRh+7UoivvUWvrsLYpiI+lJ0rvtvaSyfWy0RkvaXbuJTmv47tJaOvaWJ55fjtLk7XZ7J+nr2s1z2qrb131hLSf3ttp38Ynn72n0LTX6Fi8dtqsBtVQplw7bj6M1ur1B2H453jCawOsg6bnjhnaulj57N8dm7ye5GQIAACw761CP/GGsWsg6/H/+kIqsw5cdi1OEPoIHCZXqxTc6SNZRzVjLKrOOQ1f+MGItZB0AAGAtmOv4wVyH/37/Zq7D1z2yhz6CB8k6msU3OkjW0SQu5jo8bYOZ6wAAALi9rEMD5j2Y6/A88x7MdXj6MYTQR/DgWUe9mEYHzzqqCWpZ5VxHM/a8B3MdAAAAZB1rn+sQWcdsza4XcwQn6/hF1pGArMNkHQAAACud6/D5Izn/TeogGQhzHZ4+AyHr8DxhU+hG9/m4BVzVgFqY63B3g/kNCwAAAAAAQDa9b++m4BXeLKKgAAAAAElFTkSuQmCC"
                    />
                </defs>
            </svg>
        </SvgIcon>
    );
};

export default InventoryIcon;
